@import 'custom-variables/variables';
@import './bootstrap';
@import 'layout/header';
@import 'layout/footer';
@import './icomoon';
@import 'custom/checkbox-radio';

*,
::after,
::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 16px;

  @include media-breakpoint-down(md) {
    font-size: 15px !important;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background-color: $body-bg;
  @include media-breakpoint-down(md) {
    padding-bottom: 70px;
  }
  html[dir='rtl'] & {
    text-align: right;
  }
}

.font-arabic {
  html[dir='ltr'] & {
    font-family: $font-family-arabic;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: $gray-900;
  margin-bottom: rem(10);
}

h1,
.h1 {
  font-size: 3.25rem !important;

  @include media-breakpoint-down(md) {
    font-size: 2.75rem !important;
  }
}

h2,
.h2 {
  font-size: 2.75rem !important;

  @include media-breakpoint-down(md) {
    font-size: 2.25rem !important;
  }
}

h3,
.h3 {
  font-size: 2rem !important;

  @include media-breakpoint-down(md) {
    font-size: 1.75rem !important;
  }
}

h4,
.h4 {
  font-size: rem(24) !important;
}

h5,
.h5 {
  font-size: rem(22) !important;
}

h6,
.h6 {
  font-size: rem(20) !important;
}

a,
button {
  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
  }
}
.container {
  width: 100% !important;
  max-width: 100% !important;

  @include media-breakpoint-up(xl) {
    padding-left: rem(24);
    padding-right: rem(24);
  }
}
.content-wrapper {
  display: flex;
  background: #fff;
}

.outer {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: calc(100vh - 71px);
}

.font-weight-medium {
  font-weight: 500;
}

.form-centered {
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.submitButton {
  &--topgap {
    margin-top: 3rem;
  }
}

.gutter {
  &-2 {
    margin-left: -2px;
    margin-right: -2px;

    & > [class*='col'] {
      padding-left: 2px;
      padding-right: 2px;
    }
  }
  &-5 {
    margin-left: -5px !important;
    margin-right: -5px !important;

    & > [class*='col'] {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  &-8 {
    margin-left: -8px;
    margin-right: -8px;

    & > [class*='col'] {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  &-10 {
    margin-left: -10px;
    margin-right: -10px;

    & > [class*='col'] {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  &-12 {
    margin-left: -12px;
    margin-right: -12px;

    & > [class*='col'] {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}

.font-xxxs {
  font-size: 10px;
}
.font-xxs {
  font-size: 11px;
}
.font-xs {
  font-size: 12px !important;
}

.font-sm {
  font-size: 13px !important;
}

.font-md {
  font-size: 14px !important;
}

.font-lg {
  font-size: 15px;
}

.font-base {
  font-size: 16px !important;
}

.font-xl {
  font-size: rem(18) !important;
}

.font-xxl {
  font-size: rem(20) !important;
}

.text-medium {
  color: $gray-700;
}

.text-green {
  color: #48cc45;
}

.text-blue {
  color: #188cdf;
}

.icon-plus {
  width: 15px;
  height: 15px;
  position: relative;
  display: inline-flex;

  html[dir='ltr'] & {
    margin-right: 12px;
  }

  html[dir='rtl'] & {
    margin-left: 12px;
  }

  &:before,
  &:after {
    content: '';
    width: 13px;
    height: 2px;
    background: $primary;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center center;
  }

  &:after {
    transform: translate(-50%, -50%) rotate(90deg);
  }

  &--white {
    &:before,
    &:after {
      background: #fff;
    }
  }
}

.select__control,
.css-yk16xz-control,
.css-1pahdxg-control {
  box-shadow: none !important;
  border-color: hsl(0, 0%, 80%) !important;
  border-radius: 0 !important;
  height: 46px !important;

  &:hover {
    box-shadow: none !important;
    border-color: hsl(0, 0%, 80%) !important;
  }
}

.css-1insrsq-control {
  height: 46px !important;
}

.overflow-hidden {
  overflow: hidden;
}

.list-unstyled {
  list-style-type: none;
  html[dir='ltr'] & {
    padding-left: 0;
  }
  html[dir='rtl'] & {
    padding-right: 0;
  }
}

.img-fluid {
  max-width: 100%;
}

.cart-outer-blk {
  @include media-breakpoint-down(sm) {
    position: fixed;
    top: 0;
    width: 100%;
    left: 100%;
    background: #fff;
    z-index: 9999;
    overflow-y: scroll;
    height: 100%;
    padding: 0;
    transition: all 0.3s ease-in-out;
    &.active {
      left: 0;
    }
  }
}

.notification-outer-blk {
  @include media-breakpoint-down(sm) {
    position: fixed;
    top: 0;
    width: 100%;
    left: 100%;
    background: #fff;
    z-index: 9999;
    overflow-y: scroll;
    height: 100%;
    padding: 0;
    transition: all 0.3s ease-in-out;
    &.active {
      left: 0;
    }
    @media (max-width: 767.98px) {
      i {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
}

// .product-table-counter {
//   @include media-breakpoint-down(sm) {
//     padding-left: 105px;
//   }
// }

.product-table-image {
  @include media-breakpoint-down(sm) {
    width: 73px;
  }
}

.product-table {
  & > tbody {
    @include media-breakpoint-down(sm) {
      & > tr {
        display: flex;
        flex-wrap: wrap;
        & > td {
          &:nth-child(2) {
            flex: 0 0 calc(100% - 105px);
          }
        }
      }
    }
  }
}

.cartBox {
  @include media-breakpoint-up(lg) {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

.product-detail-popup {
  @include media-breakpoint-down(sm) {
    position: fixed;
    left: 100%;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: $white;
    z-index: 999;
    transition: all 0.5s ease-in-out;
  }
  &.active {
    left: 0;
  }
}

.product-detail-bottom {
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
}

.bg-white-sm {
  @include media-breakpoint-down(sm) {
    background-color: $white;
  }
}

.leftbar-menu {
  @include media-breakpoint-down(md) {
    position: fixed;
    z-index: 999;
    background-color: white;
    top: 0;
    height: 100%;
    overflow: auto;
    left: 100%;
    transition: all 0.5s ease-in-out;
    width: 100%;
  }
  &.active {
    left: 0;
  }
}

.content {
  min-height: calc(100vh - 84px);
}

.nowrap-white-space {
  white-space: nowrap;
}

.select__menu {
  z-index: 9999;
}

.signin-block {
  .appselect {
    .flag__selected-flag {
      width: 53px;
      html[dir='ltr'] & {
        padding: 0 22px 0 6px;
      }
      html[dir='rtl'] & {
        padding: 0 6px 0 22px;
      }
      .flag {
        html[dir='ltr'] & {
          margin-right: 0;
        }
        html[dir='rtl'] & {
          margin-left: 0;
        }
      }
      .country__code {
        display: none;
      }
    }
    .flag__dropdown {
      input,
      input[type='text'],
      input[type='tel'] {
        padding: 12px;
        font-size: 13px;
        html[dir='ltr'] & {
          padding-left: 64px;
        }
        html[dir='rtl'] & {
          padding-right: 64px;
        }
      }
    }
  }
}

.slick-slider {
  .slick-list {
    margin-left: -10px;
    margin-right: -10px;
  }
  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    height: auto;
    & > div {
      height: 100%;
    }
  }
  .slide {
    padding: 0 10px;
    height: 100%;
    &:focus {
      outline: none;
    }
  }
  .slick-arrow {
    width: rem(34);
    height: rem(34);
    border: 3px solid $body-bg;
    background: $white !important;
    border-radius: 50%;
    font-weight: 700;
    z-index: 1;
    &:before {
      font-family: icomoon;
      color: $gray-900;
    }
    &:hover {
      background: $white;
    }
    &.slick-next {
      right: rem(-21);
      &:before {
        content: '\37';
        font-size: 12px;
      }
    }
    &.slick-prev {
      left: rem(-21);
      &:before {
        content: '\36';
        font-size: 12px;
      }
    }
  }
  .slick-dots {
    position: relative;
    bottom: 0;
    margin-top: 8px;
    li {
      width: auto;
      height: auto;
      &.slick-active {
        button {
          background-color: $primary;
        }
      }
    }
    button {
      padding: 0;
      width: 9px;
      height: 9px;
      background-color: $white;
      border-radius: 50%;
      margin: 0;
      &::before {
        display: none;
      }
    }
  }
}

.line-height-normal {
  line-height: normal !important;
}

.line-height {
  line-height: 1 !important;
}

.content,
.home {
  @include media-breakpoint-down(md) {
    padding-bottom: 60px !important;
  }
}

.performance-search .select__control {
  height: 2.2rem !important;
  min-height: 20px !important;
}

@each $name, $color in $grays {
  .text-gray-#{$name} {
    color: $color !important;
  }

  .bg-gray-#{$name} {
    background-color: $color;
  }
}

.copy {
  border: 1px dashed #abc2da;
  height: 27px;
  line-height: 27px;
  border-radius: 3px;
  &--sm {
    height: 19px;
    line-height: 18px;
    padding: 0 6px;
  }
  // &:hover {
  //   background: #eff4f9;
  //   border: 1px dashed #abc2da;
  //   color: $primary;
  //   i{
  //     color: $primary !important;
  //   }
  // }
}

.cartPopup.active {
  .copy--sm {
    height: 21px;
    line-height: 20px;
  }
}

.highlight {
  background-color: #1476bd;
  color: #fff;
  box-shadow: -3px 0 0 #1476bd, 3px 0 0 #1476bd;
  padding: 0;
}

.shadow {
  box-shadow: $shadow !important;
}

.css-1ybpa09-control,
.css-95ffht-control {
  border-radius: 3px !important;
  font-weight: 500;
}

.rw-dropdown-list {
  .rw-input {
    font-size: 14px;
  }

  .rw-placeholder {
    font-weight: 400;
  }

  & > .rw-widget-container {
    &:hover {
      border-color: #cfd6dd !important;
    }
  }
  .rw-popup-container {
    margin-top: -1px;
    left: 0;
    right: 0;

    .rw-filter-input {
      margin: 18px;
      margin-bottom: 10px;
      border: 1px solid #cfd6dd;
      border-radius: 3px;
      box-shadow: none;
      height: 46px;
      padding: 0;
      .rw-btn-select {
        color: #5c7a94;
        top: -2px;
      }
      input {
        padding: 0 15px;
      }
    }
  }
  .rw-popup-transition {
    margin-bottom: 0;
    padding: 0;
  }
}

.rw-popup {
  border: 1px solid #cfd6dd !important;
  box-shadow: 0px 8px 7px rgba(0, 0, 0, 0.05) !important;
  .rw-list {
    max-height: 233px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: #ebebeb;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #c5c5c5;
    }
  }
  .rw-list-option {
    font-weight: 500;
    font-size: 14px;
    color: $gray-700 !important;
    border: 0 !important;
    padding: 8px 18px;
    &:hover,
    &.rw-state-focus,
    &.rw-state-focus:hover {
      background-color: #f9fafc;
      border: none;
    }
    &.rw-state-selected {
      background-color: $primary;
      color: $white !important;
      &:hover {
        background-color: $primary;
        color: $white !important;
      }
    }
  }

  .rw-list-empty {
    font-weight: 500;
    font-size: 14px;
    color: $gray-700 !important;
    padding: 8px 18px;
  }
}

.btn-add {
  width: 115px;
}
.inner-wrapper {
  background-color: $white !important;
  min-height: calc(100vh - 82px);
  transition: all 0.4s ease-in-out;

  @include media-breakpoint-up(lg) {
    html[dir='ltr'] & {
      padding-left: 60px;
    }
    html[dir='rtl'] & {
      padding-right: 60px;
    }
  }
  // margin-top: 90px;
  .fix-head {
    top: 90px;
  }
}

.grid-table {
  width: 100%;
  overflow: auto;
  overflow-y: hidden;
  // height: 70vh;
  // height: calc(100vh - 280px);

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(245, 245, 245, 1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-400;
    border-radius: 10px;
  }

  .responsive-label {
    display: none;
  }

  &.no-border {
    border-top: 1px solid $border-color;

    html[dir='ltr'] & {
      margin-left: 0;
    }

    html[dir='rtl'] & {
      margin-right: 0;
    }

    table {
      th {
        box-shadow: none;
      }

      th,
      td {
        border: none;
        border-bottom: 1px solid $border-color;
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    min-width: 1024px;
    white-space: nowrap;

    th,
    td {
      &[data-attr='Total'],
      &[data-attr='Amount'],
      &[data-attr='Paid'],
      &[data-attr='Balance'] {
        html[dir='ltr'] & {
          text-align: right;
        }
        html[dir='rtl'] & {
          text-align: left;
        }
      }
    }

    th {
      font-size: 13px;
      position: sticky;
      top: 0;
      background-color: $white;
      z-index: 1;
      padding: 13px 15px;
      border: 1px solid $border-color;

      &:not(.check-all):not(.action) {
        min-width: 88px;
      }

      &.check-all,
      &.action {
        z-index: 2;
        // box-shadow: inset 1px 1px 0 $border-color, inset -1px -1px 0 $border-color;
      }
    }

    td {
      font-size: 14px;
      color: $gray-900;
      padding: 12px 15px;
      border: 1px solid $border-color;
    }

    .new {
      td,
      th {
        font-weight: 700;
      }
    }

    .user-name {
      width: 100px;
    }
  }

  .table-no-border {
    th {
      box-shadow: none;
    }

    th,
    td {
      border: none;
      border-bottom: 1px solid $border-color;
    }
  }

  [type='checkbox']:not(:checked) + label,
  [type='checkbox']:checked + label {
    html[dir='ltr'] & {
      padding-left: 18px;
    }

    html[dir='rtl'] & {
      padding-right: 18px;
    }
  }

  .check-all {
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 1;
    width: 50px;

    html[dir='ltr'] & {
      left: 0;
    }

    html[dir='rtl'] & {
      right: 0;
    }

    & + td,
    & + th {
      html[dir='ltr'] & {
        border-left: none;
      }

      html[dir='rtl'] & {
        border-right: none;
      }
    }
  }

  .sort {
    font-size: 4px;
    cursor: pointer;

    i {
      margin: 1px 0;
    }

    &.asc {
      .icon-caret-up {
        color: rgba($gray-600, 0.4);
      }
    }

    &.desc {
      .icon-caret-down {
        color: rgba($gray-600, 0.4);
      }
    }
  }

  .tag {
    padding-bottom: 1px;
  }

  .grid-table-filter {
    position: absolute;
    bottom: 0;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.18);
    max-width: rem(390);
    transform: translateY(100%) scale(0.95);
    transform-origin: top center;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    visibility: hidden;

    html[dir='ltr'] & {
      right: 0;
    }

    html[dir='rtl'] & {
      left: 0;
    }

    &.in {
      transform: translateY(100%) scale(1);
      opacity: 1;
      visibility: visible;
    }

    .heading {
      padding: 6px 0;

      .icon-caret-up {
        display: none;
      }

      &.active {
        .icon-caret-up {
          display: block;
        }

        .icon-caret-down {
          display: none;
        }
      }
    }

    .filter-top {
      .form-control {
        background-image: url(../images/search-icon.png);
        background-repeat: no-repeat;

        html[dir='ltr'] & {
          background-position: left 10px center;
          padding-left: 35px;
        }

        html[dir='rtl'] & {
          background-position: right 10px center;
          padding-right: 35px;
        }
      }
    }

    .filter-middle {
      max-height: 345px;
      overflow: auto;
    }
  }
}

.widget-table {
  table {
    min-width: auto !important;
  }
}

@include media-breakpoint-down(md) {
  .overflow-lg-auto {
    overflow: auto;
  }
}

.custom-tab {
  li {
    padding: 5px 0;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    html[dir='ltr'] & {
      margin-right: 20px;
      @include media-breakpoint-up(lg) {
        margin-right: 35px;
      }
    }
    html[dir='rtl'] & {
      margin-left: 20px;
      @include media-breakpoint-up(lg) {
        margin-left: 35px;
      }
    }
    a {
      color: $gray-600 !important;
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
    }
    &.active {
      border-color: $primary;

      a {
        color: $primary !important;
      }
    }
  }
}
.menu-exp {
  span {
    font-size: 9px;
    letter-spacing: 1.5px;
    padding-top: 3px;
  }
}

.bg-body {
  background-color: $body-bg !important;
}

.border-gray {
  border-color: #bdbdbd !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-width-5 {
  border-width: 5px !important;
}

.btn-no-shadow:focus {
  box-shadow: none;
}

.bg-gray {
  background-color: #f9fafc !important;
}

.top-0 {
  top: 0;
}

.left-0 {
  html[dir='ltr'] & {
    left: 0;
  }
  html[dir='rtl'] & {
    right: 0;
  }
}

.right-0 {
  html[dir='ltr'] & {
    right: 0;
  }
  html[dir='rtl'] & {
    left: 0;
  }
}

.zIndex-9 {
  z-index: 9;
}

.minw-0 {
  min-width: 0;
}

.page {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px;
  border-top: 1px solid $border-color;
  position: relative;
  z-index: 1;
  margin-top: -1px;
  font-size: 12px;
  @include media-breakpoint-up(xl) {
    padding: 15px 24px;
  }
  .rw-widget-picker {
    height: 32px !important;
    padding: 0 !important;
    border-radius: 4px !important;
  }

  .rw-btn {
    bottom: -3px;
    html[dir='ltr'] & {
      right: 2px;
    }
    html[dir='rtl'] & {
      left: 2px;
    }
  }
}

.pagination {
  margin: 0;
  padding: 0;
}

.page-link {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  text-align: center;
  font-size: 12px !important;
  font-weight: 600;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.65rem !important;
  color: $gray-900 !important;

  i {
    font-size: 10px;
  }
}

.page-item {
  margin: 0 3px;

  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }
  &.active {
    .page-link {
      color: $white !important;
    }
  }
}

.page-control {
  .page-link {
    background-color: $white;
    border: 1px solid #ccc;
    font-size: 10px;
  }
}

.rw-widget {
  width: 100%;
}

.icon-rtl:before {
  html[dir='rtl'] & {
    transform: rotate(180deg);
    display: inline-flex;
  }
}

.text-underline {
  text-decoration: underline;
  text-underline-offset: 2px;
}
.opacity-0 {
  opacity: 0;
}
.border-width-2 {
  border-width: 2px !important;
}

.top-0 {
  top: 0;
}

.left-0 {
  html[dir='ltr'] & {
    left: 0;
  }

  html[dir='rtl'] & {
    right: 0;
  }
}

.right-0 {
  html[dir='ltr'] & {
    right: 0;
  }

  html[dir='rtl'] & {
    left: 0;
  }
}

.bottom-0 {
  bottom: 0;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-inherit {
  overflow: inherit !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-xl-visible {
  @include media-breakpoint-up(xl) {
    overflow: visible !important;
  }
}

textarea.form-control {
  height: 114px;
  resize: none;
}

.rw-widget-input {
  box-shadow: none !important;
  height: 46px !important;

  .rw-select {
    height: 28px;
    top: -2px;
  }
}

.rw-i-caret-down {
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '\e98e' !important;
    font-family: 'icomoon' !important;
    font-size: 10px;
  }
}

.confirm-msg {
  min-height: calc(100vh - 90px);
}

.bg-primary-200 {
  background-color: rgba($primary, 0.2);
}

.daterangepicker {
  font-family: inherit !important;
  .calendar-table {
    th,
    td {
      font-size: 13px !important;
    }
  }
  td {
    &.active,
    &.active:hover {
      background-color: $primary !important;
    }
  }
  &.show-calendar .drp-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.css-1s2u09g-control,
.css-1pahdxg-control {
  min-height: 46px !important;
  font-size: 0.875rem;
  color: #202020;
  font-weight: 500;
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
}

.css-2acon-control {
  border-color: #d2d2d2 !important;
  box-shadow: none !important;
}

.filter-date {
  .dateRange {
    input {
      background: url('../images/calender.png') no-repeat;
      html[dir='ltr'] & {
        background-position: center right 10px;
        padding-right: 25px;
      }
      html[dir='rtl'] & {
        background-position: center left 10px;
        padding-left: 25px;
      }
      @include media-breakpoint-up(lg) {
        border: 0;
        height: 34px;
        line-height: 34px;
      }
    }
  }
}

.graph-legend-box {
  width: 14px;
  height: 14px;
}

.time-tag {
  font-size: 12px;
  padding: 0 6px;
  height: 18px;
  line-height: 18px;
  border-radius: 2px;
  display: inline-block;
  &.old {
    background: #ffdddd;
    color: #dc3545;
  }
  &.new {
    background: #bee1fa;
    color: #188cdf;
  }
}

.word-break {
  word-break: break-word;
}

.detail-popup {
  z-index: 1050;
  overflow-x: hidden !important;

  html[dir='ltr'] & {
    transition: right 0.4s ease-in-out;
    right: -100%;
    &.in {
      right: 0;
    }
  }
  html[dir='rtl'] & {
    transition: left 0.4s ease-in-out;
    left: -100%;
    &.in {
      left: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    max-width: 95%;
    max-width: calc(100% - 60px);
  }

  .bg-gray {
    background-color: #f9fafc;
  }
}

.img-cover {
  object-fit: cover;
}

.return-table {
  @include media-breakpoint-up(xl) {
    .rw-widget > .rw-widget-container {
      min-width: 200px;
      height: 37px !important;
    }
    &.drop-open {
      overflow: inherit !important;
    }
  }
  @include media-breakpoint-down(lg) {
    overflow: inherit;

    thead {
      display: none;
    }
    table,
    tbody,
    td {
      display: block !important;
    }

    table {
      min-width: auto;
      white-space: inherit;
      tr {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid $border-color;
        margin: 0 -15px;
        padding: 10px 0;
      }

      td {
        border: none;
        padding: 6px 15px !important;
        flex: 0 0 50%;
        max-width: 50%;

        &:first-child {
          flex: 0 0 8.33333333%;
          max-width: 33px;

          html[dir='ltr'] & {
            padding-right: 0 !important;
          }
          html[dir='rtl'] & {
            padding-left: 0 !important;
          }
        }

        &:nth-child(2) {
          flex: 0 0 89.66666667%;
          max-width: 89.66666667%;

          .responsive-label {
            display: none;
          }
        }
        &:nth-child(6) {
          flex: 0 0 100%;
          max-width: 100%;
          order: 13;
        }
      }
    }

    .responsive-label {
      display: block;
    }
  }
}

.box-xl {
  max-width: 1124px;
}

.box-md {
  max-width: 627px;
}

.tooltip {
  .tooltiptext {
    visibility: hidden;
    opacity: 0;
    bottom: -10px;
    z-index: 99;
    width: rem(371);
    transition: opacity 0.3s;
    border: 1px solid #cfd6dd;
    transform: translateY(100%);
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);

    html[dir='ltr'] & {
      right: -17px;
    }

    html[dir='rtl'] & {
      left: -17px;
    }

    &::after {
      content: '';
      position: absolute;
      top: -6px;
      width: 10px;
      height: 10px;
      background-color: $white;
      border-width: 1px;
      border-style: solid;
      border-color: #cfd6dd transparent transparent #cfd6dd;
      transform: rotate(45deg);

      html[dir='ltr'] & {
        right: 17px;
      }

      html[dir='rtl'] & {
        left: 17px;
      }
    }
  }

  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  &--sm .tooltiptext {
    width: 150px;
  }
  &.left {
    html[dir='ltr'] & {
      .tooltiptext {
        left: -17px;
        &::after {
          left: 15px;
        }
      }
    }

    html[dir='rtl'] & {
      .tooltiptext {
        right: -17px;
        &::after {
          right: 15px;
        }
      }
    }
  }
  &.sm .tooltiptext {
    max-width: 215px;
  }
  &.top {
    .tooltiptext {
      transform: translateY(-100%);
      bottom: 7px;
      &::after {
        transform: rotate(-133deg);
        bottom: -6px;
        top: auto;
      }
    }
  }
  &.delete {
    html[dir='ltr'] & {
      .tooltiptext {
        left: -5px;
        &::after {
          left: 5px;
        }
      }
    }

    html[dir='rtl'] & {
      .tooltiptext {
        right: -5px;
        &::after {
          right: 5px;
        }
      }
    }
  }
}
.z-9 {
  z-index: 9;
}
.btn-check {
  min-width: 76px;
  line-height: 15px;
}

.customer-branch {
  .css-1ilr16c-control,
  .css-2acon-control {
    min-height: 50px;
    height: auto;
  }
}

.alertPopup {
  max-width: 500px !important;
}

.estimate-quotation-table {
  .textField__input,
  .form-control {
    height: 34px;
    padding: 5px 12px;
  }

  textarea {
    height: 59px;
    width: 280px;
  }
}

.click-copy-block {
  &:hover {
    background-color: $white;

    i {
      color: $white !important;
    }

    .clickCopy {
      opacity: 1;
      visibility: visible;
    }
  }

  .clickCopy {
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;

    .text {
      padding: 7px 13px;
      margin-bottom: 7px;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 12px;
      border-top: 7px solid $primary;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
    }
  }
}

.upload-blk {
  .uploading-group-inn {
    background-color: $white !important;
  }
}
.supersede-row {
  border-top: 1px dashed $border-color !important;

  .supersede-tag {
    top: -16px;
    padding: 2px 5px;
  }
}

.rw-btn {
  color: $gray-700 !important;
  opacity: 1 !important;

  html[dir='ltr'] & {
    right: 8px;
  }

  html[dir='rtl'] & {
    left: 8px;
  }
}

.customchk {
  .rw-btn {
  
    html[dir='ltr'] & {
      right: 0;
    }
  
    html[dir='rtl'] & {
      left: -5px;
    }
  }
}

.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  color: $gray-700 !important;

  html[dir='ltr'] & {
    margin-right: 5px;
  }

  html[dir='rtl'] & {
    margin-left: 5px;
  }

  &:hover {
    color: $gray-700 !important;
  }

  &:last-child {
    &::before {
      content: '\e98e' !important;
      font-family: 'icomoon' !important;
      font-size: 10px;
    }

    svg {
      display: none;
    }
  }
}

.css-1rhbuit-multiValue {
  background-color: #f4f7fa !important;
}

.css-12jo7m5 {
  font-size: 14px !important;
  font-weight: 500;
  padding: 2px 7px !important;
  color: $body-color !important;
}

.css-xb97g8 {
  width: 25px;
  justify-content: center;
  color: $gray-700;

  &:hover {
    background-color: $primary !important;
    color: $white !important;
  }

  &::before {
    content: '\e99d' !important;
    font-family: 'icomoon' !important;
    font-size: 8px;
    font-weight: 600;
  }

  svg {
    display: none;
  }
}

.css-319lph-ValueContainer {
  padding: 2px 10px !important;
}

.rw-dropdown-list-input {
  padding-right: 14px !important;
}

.rw-dropdown-list-input,
.css-qc6sy-singleValue {
  font-weight: 500;
  color: $body-color !important;
}

.css-tj5bde-Svg {
  width: 18px;
}

.rw-placeholder,
.css-14el2xx-placeholder {
  color: $input-placeholder-color !important;
}

.notification-popup {
  .rw-widget-picker {
    height: 34px !important;
  }
}

.rounded-sm {
  border-radius: 2px;
}

.custom-scroll-vertical {
  overflow-x: hidden !important;

  html[dir='ltr'] & {
    margin-right: 1px !important;
  }
  html[dir='rtl'] & {
    margin-left: 1px !important;
  }
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
  }
}

.custom-scroll-horizontal {
  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $white;
  }

  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
  }
}

.search-tag {
  .tag {
    padding: 4px 13px;
  }
}

.daterangepicker {
  html[dir='ltr'] & {
    margin-right: 20px;
  }

  html[dir='rtl'] & {
    margin-left: 20px;
  }
  &.opensright {
    @include media-breakpoint-up(lg) {
      &:before {
        html[dir='ltr'] & {
          left: inherit;
          right: 9px;
        }

        html[dir='rtl'] & {
          right: inherit;
          left: 9px;
        }
      }

      &:after {
        html[dir='ltr'] & {
          left: inherit;
          right: 10px;
        }

        html[dir='rtl'] & {
          right: inherit;
          left: 10px;
        }
      }
    }
    @include media-breakpoint-down(md) {
      &:before {
        html[dir='rtl'] & {
          left: inherit;
          right: 9px;
        }
      }

      &:after {
        html[dir='rtl'] & {
          left: inherit;
          right: 10px;
        }
      }
    }
  }
}

.super-sub {
  .sub-item:first-child {
    border-top: none !important;
  }

  .in {
    font-weight: 400;
    padding: 5px 0 !important;

    html[dir='ltr'] & {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    html[dir='rtl'] & {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.tag-xs {
  padding: 2px 2px 1px 2px;
  font-size: 9px;
}

.text-intitial {
  white-space: initial;
}

.css-govwq4-menu,
.css-l3nqr9-menu,
.css-26l3qy-menu {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-weight: 500;
  font-size: 14px;
}

.filter-blk > div {
  min-height: 45px;
}

.select-reason {
  font-size: 14px;

  .rw-widget-picker {
    height: 37px !important;
  }
}

.bulk-info {
  background-color: lighten(desaturate(adjust-hue($primary, 3), 38.63), 45.49);
  color: #fb2828c7;
  line-height: 16px;
}

.multi-select-block {
  .rw-multiselect-taglist {
    padding: 0 8px;

    .rw-multiselect-tag {
      background-color: #f9fafc;
      border-radius: 2px;
      border: none;
      font-size: 13px;
      font-weight: 600;
      margin: 3px;
      padding: 0;

      & > span {
        padding: 0 8px;
      }
    }

    .rw-btn {
      width: 25px;
      height: 25px;
      line-height: 25px;
      font-size: 8px;
      background-color: #f1f1f1;
      font-weight: 400;

      &:before {
        content: '\e91a';
        font-family: icomoon;
      }

      html[dir='ltr'] & {
        right: 0;
      }

      html[dir='rtl'] & {
        left: 0;
      }

      span {
        display: none;
      }
    }

    .rw-multiselect-tag-btn {
      margin: 0;
    }
  }

  .rw-multiselect {
    .rw-widget-picker > div {
      display: inline-flex;
    }
  }
}

.screen-minh {
  min-height: calc(100vh - 144px);

  &-sm {
    min-height: calc(100vh - 73px);
  }
}

.manage-brand-table {
  .item + .item {
    margin-top: 8px;
  }
}

.filter-search .form-control-search {
  html[dir='ltr'] & {
    @include media-breakpoint-up(lg) {
      background-position: right center;
    }
    @include media-breakpoint-down(md) {
      background-position: right 12px center;
    }
  }

  html[dir='rtl'] & {
    @include media-breakpoint-up(lg) {
      background-position: left center;
    }
    @include media-breakpoint-down(md) {
      background-position: left 12px center;
    }
  }
}

.filter-add {
  a {
    padding: 12px 0;
  }
}

.rw-multiselect {
  .py-2.rw-widget-input {
    padding: 6px 0 !important;
  }
}

.inner-header,
.list-head {
  .btn {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.table-hover tr,
.hoverTr {
  &:hover {
    background-color: #f9fafc;
  }
}

.graph-filter {
  .rw-widget-input {
    height: 36px !important;
  }

  .rw-multiselect .rw-input-reset {
    height: 34px;
  }

  .rw-multiselect .rw-input-reset,
  .rw-dropdown-list .rw-input,
  .rw-popup .rw-list-option,
  .multi-select-block .rw-multiselect-taglist .rw-multiselect-tag {
    font-size: 12px;
  }
}

@include media-breakpoint-up(lg) {
  .border-lg-0 {
    border: none;
  }
}

.table-auto table {
  min-width: auto;
}

.banner-content {
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  color: #fff;
  width: 100%;
  line-height: 1;
}

.activity-timeline {
  .activity-icon {
    width: 18px;
    height: 18px;
    border: 1px solid rgba(111, 113, 113, 0.2);
    margin-top: 6px;
    position: relative;
    z-index: 2;
  }
  .activity-changelog {
    &:after {
      content: '';
      width: 1px;
      z-index: 0;
      height: calc(100% + 24px);
      position: absolute;
      border-left: 2px solid #ddd;
      z-index: 1;
      top: 6px;
      html[dir='ltr'] & {
        left: rem(9);
      }
      html[dir='rtl'] & {
        right: rem(9);
      }
    }
    &:last-child::after {
      display: none;
    }
  }
}

.pt-px,
.py-px {
  padding-top: 2px;
}

.pb-px,
.py-px {
  padding-bottom: 2px;
}
