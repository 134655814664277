@import '../../../assets/sass/custom-variables/variables';
.cartBlock {
  box-shadow: $shadow;
  position: sticky;
  top: 106px;
  z-index: 101;
  @media (max-width: 991px) {
    // height: calc(100vh - 45px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: $white;
    display: none;
    &.active {
      display: block;
    }
  }
  ul {
    li {
      font-size: 16px;
      padding: rem(18) 0;
    }
  }
  .transform-btm {
    transform: translateY(-5px);
  }
  &__head {
    background-color: $white;
    display: flex;
    padding: 13px 20px 0px 20px;
    flex-wrap: wrap;
    align-items: center;
    @media (max-width: 991px) {
      flex-direction: column;
      padding: 0 rem(15) rem(15) rem(15);
      display: none;
    }
    &.active {
      height: 97px;
    }
  }
  &__header {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  &__item {
    background-color: $white;
    padding: 15px 20px 15px 20px;
    // margin-top: 4px;
    // border-top: 1px solid #E4E4E4;
    &--total {
      span {
        min-width: 100px;
        display: inline-block;
      }
    }
  }
  &__itemHead {
    text-align: center;
    font-size: 22px;
    font-weight: 500;
  }
  &__img {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  &__offer {
    background-color: #fffbe7;
    border-top: 1px solid #e4e4e4;
    padding: 30px 0px 10rem 0px;
  }
  &__offerText {
    text-align: center;
    font-size: 14px;
    justify-content: center;
    font-weight: 500;
  }
  &__span {
    color: #ff6600;
    font-weight: 600;
  }
  .price-block {
    text-align: right;
    padding: 0 rem(20);
    @media (max-width: 991px) {
      padding: 0;
      text-align: left;
      margin-top: rem(4);
    }
    // @media (max-width: 575px){
    //     padding: 0 rem(16);
    //     text-align: left;
    // }
    &__totalItems {
      font-size: 13px;
      color: $gray-600;
      // margin-bottom: 5px;
      @media (max-width: 991px) {
        &:last-child {
          display: none;
        }
      }
    }
    &__span {
      font-size: 13px;
      font-weight: 600;
      color: $gray-900;
    }
  }

  .Button {
    display: block;

    &__left {
      float: left;
      font-size: 18px;
      margin-left: 0px;
      margin-left: -28px;
    }
    &__right {
      float: right;
      font-size: 18px;
      margin-right: -20px;
    }
  }

  .textField {
    margin-bottom: 0;
  }
  &__button {
    @media (max-width: 991px) {
      position: sticky;
      bottom: 0;
      left: 0;
      width: 100%;
      top: calc(100vh - 50px);
    }
  }
  .Modal__content {
    max-width: 409px;
  }
  .promo-btn {
    padding: 5px 14px;
    border-radius: 3px;
  }
}

.cartPopup {
  // &:not(.active) {
  //   max-height: calc(100vh - 245px);
  //   @media (max-width: 991px) {
  //     max-height: calc(100vh - 56px);
  //   }
  // }
  // overflow: auto;
  &.active {
    right: -40%;
    top: 0;
    max-width: 33.33333%;
    z-index: 999;
    box-shadow: 2px 0px 24px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    @media (max-width: 575px) {
      max-width: 100%;
    }
    &.isOpen {
      right: 0;
    }
  }
}

.cart_notification {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $primary;
  position: relative;
  display: inline-block;
  margin-left: 8px;
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid $primary;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1);
    background-color: transparent;
  }
  &::before {
    animation: wave 1s ease-in-out infinite;
  }
  &::after {
    animation: wave 1s ease-in-out 0.5s infinite;
  }
}

@keyframes wave {
  from {
    border: 1px solid $primary;
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    border: 1px solid rgba($primary, 0);
    transform: translate(-50%, -50%) scale(3);
  }
}

.cartBlock i.icon-bin {
  box-shadow: none;
  position: absolute;
  right: -24px;
  top: -5px;
  margin-top: 4px;
  opacity: 0;
  display: block;
  transition: all 0.5s ease-in-out;
  color: #1476bd;
  cursor: pointer;
  @media (max-width: 767.98px) {
    position: relative !important;
    top: 0px !important;
    left: 0px !important;
    margin-top: 0;
    opacity: 1;
    order: 4;
    color: #858585;
    font-size: 15px;
  }
}

.cartItem:hover i.icon-bin {
  opacity: 1;
  z-index: 99;
  position: absolute;
  top: 5px;
}

.cart-info {
  background: #f9fafc;
  border: 1px dashed #bdbdbd;
  border-radius: 3px;
}
@media (min-width: 992px) {
  .second-screen .cartBlock {
    position: static;
  }
}

.delete-confirm-popup {
  min-height: 280px;

  .rw-popup .rw-list {
    max-height: 120px;
  }
}
